import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Breadcrumb } from '../components/Breadcrumbs/Breadcrumbs'
import { AUTHORS as AUTHORS_LINK } from '../components/internal-links'
import {
  generateBreadcrumbsSchema,
  generateOrgSchema,
  generatePersonIrshadSchema,
  generatePersonKanchanSchema,
  generatePersonRohanSchema,
  generatePersonShahlaSchema,
  generateWebsiteSchema,
} from '../lib/generate-schema'
import Blogs from '../components/Blogs/Blogs'
import AUTHOR_PAGES from '../author-tags'
import './authors.scss'
import {
  pokerOneLinksStaticPage,
  rummyOneLinksStaticPage,
} from '../components/one-links'
import { HOMEPAGE_BREADCRUMB_TITLE } from '../utils/constnst.utils'

const breadcrumbs: Breadcrumb[] = [
  {
    title: HOMEPAGE_BREADCRUMB_TITLE,
    url: '/',
  },
  {
    title: 'Authors',
    url: AUTHORS_LINK,
  },
]

const Authors: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      iconList: file(relativePath: { eq: "icon-list.png" }) {
        childImageSharp {
          gatsbyImageData(width: 14, layout: FIXED)
        }
      }
      iconGrid: file(relativePath: { eq: "icon-grid.png" }) {
        childImageSharp {
          gatsbyImageData(width: 14, layout: FIXED)
        }
      }
    }
  `)

  const getAllAuthorSchema = [
    generatePersonKanchanSchema(),
    generatePersonRohanSchema(),
    generatePersonIrshadSchema(),
    generatePersonShahlaSchema(),
  ]

  const [authorLayout, setAuthorLayout] = useState('list')

  return (
    <Layout
      showBreadcrumbs
      breadcrumbs={breadcrumbs}
      pokerOneLink={pokerOneLinksStaticPage}
      rummyOneLink={rummyOneLinksStaticPage}
    >
      <Helmet>
        {process.env.GATSBY_STAGING === 'true' && (
          <meta name="robots" content="noindex" />
        )}
      </Helmet>
      <SEO
        title="Authors at Mega"
        description="Read about the authors at Mega Blogs."
        organizationSchema={generateOrgSchema()}
        personSchema={getAllAuthorSchema}
        websiteSchema={generateWebsiteSchema()}
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
      />
      <div className="authors-list-wrap">
        <div className="authors-list-heading">
          <h1>Bloggers of Mega</h1>
          <div className="authors-swtich">
            <button
              type="button"
              className={`${authorLayout === 'list' ? 'active' : 'not'}`}
              onClick={() => {
                setAuthorLayout('list')
              }}
            >
              <GatsbyImage
                image={data.iconList.childImageSharp.gatsbyImageData}
                alt="icon"
                imgStyle={{ objectFit: 'contain' }}
              />
            </button>
            <button
              type="button"
              className={`${authorLayout === 'grid' ? 'active' : 'not'}`}
              onClick={() => {
                setAuthorLayout('grid')
              }}
            >
              <GatsbyImage
                image={data.iconGrid.childImageSharp.gatsbyImageData}
                alt="icon"
                imgStyle={{ objectFit: 'contain' }}
              />
            </button>
          </div>
        </div>

        <div
          className={`authors-list ${
            authorLayout === 'grid' ? 'grid-layout' : 'list-layout'
          }`}
        >
          <Blogs
            blogType="authors"
            tags={AUTHOR_PAGES}
            prefix={`/${process.env.GATSBY_CMS_AUTHOR_PREFIX}` || '/'}
            title={<>Bloggers of Mega</>}
            displayFilters={false}
            searchOption={false}
          />
        </div>
        <div className="author-contact">
          <h5>Contribute to the Mega blog</h5>
          <p>Want to contribute to the Mega blog and serve as an author?</p>
          <p>
            That&apos;s great! just drop us an email at{' '}
            <a href="mailto:contact@getmega.com">contact@getmega.com</a>
          </p>
        </div>
      </div>
    </Layout>
  )
}
export default Authors
